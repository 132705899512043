import { inject, Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IErrorMessage } from '@models/error-message.model';

@Pipe({ name: 'errorMessage', standalone: true })
export class FormErrorMessagePipe implements PipeTransform {
  #translateService = inject(TranslateService);

  transform(
    errors: ValidationErrors,
    overrideErrMessages?: IErrorMessage
  ): string {
    if (!errors) {
      return '';
    }

    const errorKey = Object.keys(errors)[0];
    const pattern =
      typeof errors[errorKey] === 'object' ? errors[errorKey] : null;
    const translationKey =
      overrideErrMessages && overrideErrMessages[errorKey]
        ? overrideErrMessages[errorKey]
        : `validation_${errorKey}`;

    return this.#translateService.instant(translationKey, pattern);
  }
}
